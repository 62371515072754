import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { AddLeadRequest, LeadService } from '@/utils/api/services/openapi';
import { useMutation } from '@tanstack/react-query';
import { Button, ButtonSize, ButtonVariant } from './Button';
import { Input } from './Input';
import { Alert, AlertType } from './Alert';
import { Dialog } from './Dialog';
import Chat from '@/public/assets/illustrations/chat.svg';
import { DialogDescription, DialogTitle } from '@radix-ui/react-dialog';
import locale from '@/public/locales/nb/checkout.json';
import { Typography } from '@/components/Typography';
import { cn } from '@/utils/utils';
import { useRouter } from 'next/router';

enum FormKey {
  HousingCompanyName = 'housingCompanyName',
  City = 'city'
}

interface Schema {
  [FormKey.HousingCompanyName]: string;
  [FormKey.City]: string;
}

interface AddLeadDialog {
  open: boolean;
  onClose: () => void;
}

const validationSchema = yup.object().shape({
  housingCompanyName: yup
    .string()
    .required(locale.step.housingCompany.leadDialog.requiredError)
    .max(255, locale.step.housingCompany.leadDialog.housingCompanyLengthError),
  city: yup
    .string()
    .required(locale.step.housingCompany.leadDialog.requiredError)
    .max(40, locale.step.housingCompany.leadDialog.cityLengthError)
});

export const AddLeadDialog = ({ open, onClose }: AddLeadDialog) => {
  const { query } = useRouter();

  const mutation = useMutation((request: AddLeadRequest) => LeadService.add(request));
  const resetMutation = mutation.reset;

  const formMethods = useForm<Schema>({
    resolver: yupResolver(validationSchema)
  });
  const resetForm = formMethods.reset;

  useEffect(() => {
    if (!open) {
      resetForm();
      resetMutation();
    }
  }, [open, resetMutation, resetForm]);

  const onSubmit = formMethods.handleSubmit(data =>
    mutation.mutate({
      ...data,
      medium: query.utm_medium?.toString(),
      secondarySource: query.utm_source?.toString(),
      campaign: query.utm_campaign?.toString(),
      countryCode: 'NO'
    })
  );

  return (
    <Dialog
      onOpenAutoFocus={false}
      className={cn('w-[800px]', 'max-w-full', 'h-[580px]', 'p-10', 'md:py-16', 'md:px-6')}
      open={open}
      toggleOpen={onClose}
    >
      {!mutation.isSuccess && (
        <div className={cn('flex', 'h-full', 'flex-col')}>
          <FormProvider {...formMethods}>
            <form
              className={cn('flex-1', 'flex', 'flex-col', 'justify-between', 'md:justify-center', 'md:gap-12')}
              onSubmit={onSubmit}
            >
              <div className={cn('flex', 'flex-col', 'gap-4', 'max-w-[530px]')}>
                <DialogTitle>
                  <Typography type="S-H" component="span" weight="regular" mobileWeight="bold">
                    {locale.step.housingCompany.leadDialog.getAneo}
                  </Typography>
                </DialogTitle>
                <DialogDescription>
                  <Typography type="S-B" component="span">
                    {locale.step.housingCompany.leadDialog.getAneoDescription}
                  </Typography>
                </DialogDescription>
              </div>
              <div className={cn('flex', 'flex-col', 'gap-6', 'max-w-[530px]')}>
                <Input
                  id={FormKey.HousingCompanyName}
                  type="text"
                  name={FormKey.HousingCompanyName}
                  showRequiredIndicator
                  fullWidth
                  label={locale.step.housingCompany.leadDialog.housingCompany}
                />
                <Input
                  id={FormKey.City}
                  type="text"
                  name={FormKey.City}
                  showRequiredIndicator
                  fullWidth
                  label={locale.step.housingCompany.leadDialog.city}
                />
                {(formMethods.formState.errors[FormKey.HousingCompanyName] ||
                  formMethods.formState.errors[FormKey.City]) && (
                  <Alert
                    type={AlertType.Alert}
                    body={
                      <ul>
                        {(formMethods.formState.errors[FormKey.HousingCompanyName]?.type === 'required' ||
                          formMethods.formState.errors[FormKey.City]?.type === 'required') && (
                          <li>
                            {formMethods.formState.errors[FormKey.HousingCompanyName]?.message ??
                              formMethods.formState.errors[FormKey.City]?.message}
                          </li>
                        )}
                        {formMethods.formState.errors[FormKey.HousingCompanyName]?.type !== 'required' && (
                          <li>{formMethods.formState.errors[FormKey.HousingCompanyName]?.message}</li>
                        )}
                        {formMethods.formState.errors[FormKey.City] &&
                          formMethods.formState.errors[FormKey.City]?.type !== 'required' && (
                            <li>{formMethods.formState.errors[FormKey.City]?.message}</li>
                          )}
                      </ul>
                    }
                  />
                )}
                {mutation.isError && (
                  <Alert
                    type={AlertType.Alert}
                    body={<p>{locale.step.housingCompany.leadDialog.somethingWhenWrong}</p>}
                  />
                )}
              </div>
              <div className={cn('flex', 'flex-row', 'justify-end', 'gap-4')}>
                <Button
                  buttonVariant={ButtonVariant.Outline}
                  buttonSize={ButtonSize.Large}
                  onClick={onClose}
                  type="button"
                  disabled={mutation.isLoading}
                  className={cn('px-14', 'md:px-[unset]', 'md:basis-2/4')}
                >
                  {locale.step.housingCompany.leadDialog.close}
                </Button>
                <Button
                  buttonVariant={ButtonVariant.Contained}
                  buttonSize={ButtonSize.Large}
                  loading={mutation.isLoading}
                  type="submit"
                  disabled={mutation.isLoading}
                  className={cn('px-24', 'md:px-[unset]', 'md:basis-2/4')}
                >
                  {locale.step.housingCompany.leadDialog.tipUs}
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      )}
      {mutation.isSuccess && (
        <div className={cn('flex', 'h-full', 'flex-col', 'items-end', 'gap-10')}>
          <div
            className={cn(
              'flex-1',
              'flex',
              'flex-row',
              'md:flex-col',
              'items-center',
              'justify-center',
              'gap-4',
              'md:gap-8'
            )}
          >
            <div>
              <Chat />
            </div>
            <div className="flex flex-col gap-4">
              <DialogTitle>
                <Typography type="L-H" component="span">
                  {locale.step.housingCompany.leadDialog.thanks}
                </Typography>
              </DialogTitle>
              <DialogDescription>
                <Typography type="M-B" component="span">
                  {locale.step.housingCompany.leadDialog.weWillContact}
                </Typography>
              </DialogDescription>
            </div>
          </div>
          <Button
            buttonVariant={ButtonVariant.Outline}
            onClick={onClose}
            type="button"
            className={cn('px-14', 'md:px-[unset]', 'md:w-full')}
          >
            {locale.step.housingCompany.leadDialog.close}
          </Button>
        </div>
      )}
    </Dialog>
  );
};
