import { QueryKey, QueryObserverResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HousingCompanyService } from '@/utils/api/services/openapi/services/HousingCompanyService';
import { ExternalAccountResponse } from '@/utils/api/services/openapi';

const housingCompanyDefaultQueryKey = 'getHousingCompany';

const fetcher = (id: string) => {
  return HousingCompanyService.getHousingCompanyById(id);
};

const useHousingCompany = (
  id: string,
  options: Omit<
    UseQueryOptions<ExternalAccountResponse, unknown, ExternalAccountResponse, QueryKey>,
    'initialData'
  > = {}
): QueryObserverResult<ExternalAccountResponse> => {
  const query = useQuery<ExternalAccountResponse>({
    queryKey: [housingCompanyDefaultQueryKey, id],
    queryFn: () => fetcher(id),
    ...options
  });

  return {
    ...query,
    isLoading: query.isLoading && options.enabled !== false
  } as QueryObserverResult<ExternalAccountResponse>;
};

export { useHousingCompany };
