import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import React, { useRef } from 'react';
import { cn } from '@/utils/utils';

export enum RadioSize {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small'
}

export interface Props {
  id: string;
  value: string;
  label: string;
  asChild?: boolean;
  disabled?: boolean;
  required?: boolean;
  radioSize?: RadioSize;
  className?: string;
  isSelected?: boolean;
}

const RadioButton: React.FC<Props> = ({
  id,
  value,
  label,
  asChild,
  disabled,
  required,
  radioSize,
  className,
  isSelected = false
}) => {
  const radioRef = useRef<HTMLButtonElement>(null);

  const onWrapperClick = () => {
    if (radioRef.current) {
      radioRef.current.click();
      radioRef.current.focus();
    }
  };

  const radioClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <div
      onClick={onWrapperClick}
      className={cn(
        className,
        'flex',
        'items-center',
        'justify-start',
        'text-common-black',
        'border-gray-40',
        'border-2',
        'rounded-lg',
        {
          'cursor-pointer': !disabled
        },
        {
          'hover:bg-gray-20': !disabled,
          'hover:border-transparent': !disabled
        },
        {
          'bg-yellow-100': !disabled && isSelected,
          'border-yellow-100': !disabled && isSelected
        },
        {
          'active:bg-yellow-100': !disabled,
          'active:border-yellow-100': !disabled
        },
        {
          'focus-within:bg-blue-50': !disabled,
          'focus-within:outline-4': !disabled,
          'focus-within:outline': !disabled,
          'focus-within:outline-system-focus': !disabled,
          'focus-within:border-transparent': !disabled
        },
        {
          'border-gray-40': disabled,
          'text-gray-80': disabled
        },
        {
          // Large: 16px padding
          // Medium: 8px 16px padding
          // Small: 6px 16px padding
          'px-4': true,
          'py-4': radioSize == 'Large' || radioSize == undefined,
          'py-2': radioSize == 'Medium',
          'py-1.5': radioSize == 'Small',
          'md:py-1.5': radioSize == undefined
        },
        {
          'text-[1.25rem]': radioSize == 'Large' || radioSize == undefined,
          'text-[1rem]': radioSize == 'Small' || radioSize == 'Medium',
          'leading-[130%]': true
        }
      )}
    >
      <RadixRadioGroup.Item
        value={value}
        id={id}
        asChild={asChild}
        disabled={disabled}
        required={required}
        ref={radioRef}
        onClick={radioClick}
        className={cn(
          // Large/Medium 4px padding, 20px outer circle, 10px inner circle, 5px from inner to outer
          // Small 1.33px padding, 13.33px outer circle, 6.67px inner circle, 3.33px from inner to outer
          'inline-flex',
          'rounded-full',
          {
            'focus:outline-none': !disabled
          },
          {
            'border-common-black': !disabled,
            'border-gray-40': disabled
          },
          // 2px
          'border-2',
          // 2px margin
          'm-[2px]',
          // 16px + 2px margin right
          'mr-[18px]',
          {
            // Large/Medium
            'w-[20px]': true,
            'h-[20px]': true,
            // Small
            'h-[13.33px]': radioSize == 'Small',
            'w-[13.33px]': radioSize == 'Small',
            'md:h-[13.33px]': radioSize == undefined,
            'md:w-[13.33px]': radioSize == undefined
          }
        )}
      >
        <RadixRadioGroup.Indicator
          className={cn(
            'flex',
            'items-center',
            'justify-center',
            'w-full',
            'h-full',
            'relative',
            'after:content-[""]',
            'after:block',
            'after:rounded-[50%]',
            {
              'after:bg-common-black': !disabled,
              'after:bg-gray-40': disabled
            },
            {
              // Large/Medium
              'after:w-[10px]': true,
              'after:h-[10px]': true,
              // small / mobile
              'after:w-[6.67px]': radioSize == 'Small',
              'after:h-[6.67px]': radioSize == 'Small',
              'md:after:w-[6.67px]': radioSize == undefined,
              'md:after:h-[6.67px]': radioSize == undefined
            }
          )}
        />
      </RadixRadioGroup.Item>
      <label className={cn({ 'cursor-pointer': !disabled })} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export { RadioButton };
