import { QueryKey, QueryObserverResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ParkingLotDTO, ParkingLotService } from '@/utils/api/services/openapi';
import { LoggingContextType, useLogger } from '@/utils/logger';

const parkingLotsDefaultQueryKey = 'getParkingLots';

const fetcher = async (chargingFacilityId: string, logger?: LoggingContextType) => {
  if (chargingFacilityId == undefined || chargingFacilityId == '') {
    return [];
  }
  const parkingLots = await ParkingLotService.getParkingLotsByFacilityId(chargingFacilityId);

  if (parkingLots.length === 0) {
    logger?.warn('Got 0 parkingLots from charging facility {ChargingFacility}', chargingFacilityId);
  }
  return parkingLots;
};

const useParkingLots = (
  chargingFacilityId: string,
  options: Omit<UseQueryOptions<ParkingLotDTO[], unknown, ParkingLotDTO[], QueryKey>, 'initialData'> = {}
): QueryObserverResult<ParkingLotDTO[]> => {
  const logger = useLogger();

  const query = useQuery<ParkingLotDTO[]>({
    queryKey: [parkingLotsDefaultQueryKey, chargingFacilityId],
    queryFn: () => fetcher(chargingFacilityId, logger),
    ...options
  });

  return { ...query, isLoading: query.isLoading && options.enabled !== false } as QueryObserverResult<ParkingLotDTO[]>;
};

export { useParkingLots };
