import { useEffect, useState } from 'react';

interface UserLocation {
  lat: number;
  lon: number;
}

const getCurrentPosition: () => Promise<UserLocation> = () => {
  return new Promise(function (resolve, reject) {
    const options = {
      enableHighAccuracy: true,
      timeout: 60000, // wait for up to 1minute
      maximumAge: 0
    };

    navigator.geolocation.getCurrentPosition(
      function (pos) {
        const lat = pos.coords.latitude;
        const lon = pos.coords.longitude;
        const location: UserLocation = {
          lat: pos.coords.latitude,
          lon: pos.coords.longitude
        };

        resolve(location);
      },
      function (e) {
        reject(e);
      },
      options
    );
  });
};

const useUserLocation = () => {
  const [location, setLocation] = useState<UserLocation>();
  const [geoLocationUnavailable, setGeoLocationUnavailable] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(true);
  const [doHavePermission, setDoHavePermission] = useState<boolean>();

  useEffect(() => {
    const permissionHandler = (state: PermissionState) => {
      if (state == 'granted') {
        setDoHavePermission(true);
        return;
      }
      setDoHavePermission(false);
    };
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
        permissionHandler(permissionStatus.state);
        permissionStatus.onchange = () => {
          permissionHandler(permissionStatus.state);
        };
      });
    }
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        if (!navigator.geolocation) {
          setGeoLocationUnavailable(true);
          setIsLoading(false);
          return;
        } else {
          setGeoLocationUnavailable(false);
        }

        const _location = await getCurrentPosition();
        setLocation(_location);
      } catch (e) {
        setGeoLocationUnavailable(true);
      }
      setIsLoading(false);
    };

    init();
  }, [doHavePermission]);

  return { userLocation: location, geoLocationUnavailable, isLoading };
};

export { useUserLocation };
export type { UserLocation };
