import { QueryKey, QueryObserverResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HousingCompanyService } from '@/utils/api/services/openapi/services/HousingCompanyService';
import { ExternalAccountResponse } from '@/utils/api/services/openapi';

const housingCompaniesDefaultQueryKey = 'getHousingCompanies';

const fetcher = (name: string) => {
  if (name == undefined || name == '') {
    return [];
  }
  return HousingCompanyService.searchHousingCompaniesByName(name);
};

const useHousingCompanies = (
  name: string,
  options: Omit<
    UseQueryOptions<ExternalAccountResponse[], unknown, ExternalAccountResponse[], QueryKey>,
    'initialData'
  > = {}
): QueryObserverResult<ExternalAccountResponse[]> => {
  const query = useQuery<ExternalAccountResponse[]>({
    queryKey: [housingCompaniesDefaultQueryKey, name],
    queryFn: () => fetcher(name),
    ...options
  });

  return { ...query, isLoading: query.isLoading && options.enabled !== false } as QueryObserverResult<
    ExternalAccountResponse[]
  >;
};

export { useHousingCompanies };
