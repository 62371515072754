import React, { useEffect, useState } from 'react';
import { InputSelect, InputSelectProps, OptionType } from '@/components/InputSelect';
import { useChargingFacilities } from '@/utils/hooks/useChargingFacilities';
import locale from '@/public/locales/nb/global.json';

interface Props extends Omit<InputSelectProps, 'inputValue' | 'onInputChange' | 'id' | 'name' | 'options' | 'loading'> {
  housingCompanyId: string;
}

enum CHARGING_FACILITY_STATUS_PICKLIST {
  NotStarted = 'Not Started',
  AwaitingConstruction = 'Awaiting Construction Start-Up (New Construction)',
  AwaitingConnection = 'Awaiting Connection/Meter Net-Company',
  ProcessingNetCompany = 'Processing Net-Company',
  UnderConstruction = 'Under Construction',
  UnderPlanning = 'Under Planning',
  Installed = 'Installed'
}

const SelectChargingFacility: React.FC<Props> = ({ housingCompanyId, ...rest }) => {
  const [searchText, setSearchText] = useState('');
  const { isLoading, data } = useChargingFacilities(housingCompanyId, { useErrorBoundary: true });
  const [chargingFacilities, setChargingFacilities] = useState<OptionType[]>([]);

  const onSearchInputChange = (search: string) => {
    setSearchText(search);
  };

  useEffect(() => {
    const nullableOptions = data?.map(chargingFacility => {
      if (!chargingFacility.name || !chargingFacility.id) {
        return null;
      }
      const isUnavailable = chargingFacility.status !== CHARGING_FACILITY_STATUS_PICKLIST.Installed;
      const option: OptionType = {
        label: `${chargingFacility.name}${isUnavailable ? ` (${locale.chargingFacilitySelect.unavailable})` : ''}`,
        value: chargingFacility.id,
        disabled: isUnavailable
      };
      return option;
    });
    const options = nullableOptions
      ?.filter((option): option is OptionType => option !== null)
      .sort((a, b) => {
        if (a.disabled !== b.disabled) {
          return a.disabled ? 1 : -1;
        } else {
          return a.label.toString().localeCompare(b.label.toString());
        }
      });

    setChargingFacilities(options ?? []);
  }, [data]);

  return (
    <InputSelect
      id="Charging-Facility-Select"
      name="chargingFacility"
      options={chargingFacilities}
      loading={isLoading}
      onInputChange={onSearchInputChange}
      inputValue={searchText}
      autoSelectIfOnlyOneOption
      {...rest}
    />
  );
};

export { SelectChargingFacility };
