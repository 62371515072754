import React, { useEffect, useState } from 'react';
import { InputSelect, InputSelectProps, OptionType } from '@/components/InputSelect';
import { useParkingLots } from '@/utils/hooks/useParkingLots';
import locale from '@/public/locales/nb/global.json';

interface Props extends Omit<InputSelectProps, 'inputValue' | 'onInputChange' | 'id' | 'name' | 'options' | 'loading'> {
  chargingFacilityId: string;
}

const SelectParkingLot: React.FC<Props> = ({ chargingFacilityId, ...rest }) => {
  const [searchText, setSearchText] = useState('');
  const { isLoading, data } = useParkingLots(chargingFacilityId, { useErrorBoundary: true });
  const [parkingLots, setParkingLots] = useState<OptionType[]>([]);

  const onSearchInputChange = (search: string) => {
    setSearchText(search);
  };

  useEffect(() => {
    const nullableOptions = data?.map(parkingLot => {
      if (!parkingLot.name || !parkingLot.id) {
        return null;
      }
      const isUnavailable = parkingLot.status == 'Busy' || parkingLot.status == 'Reserved';
      const option: OptionType = {
        label: `${parkingLot.name}${isUnavailable ? ` (${locale.parkingLotSelect.unavailable})` : ''}`,
        value: parkingLot.id,
        disabled: isUnavailable
      };
      return option;
    });
    const options = nullableOptions
      ?.filter((option): option is OptionType => option !== null)
      .sort((a, b) => {
        if (a.disabled !== b.disabled) {
          return a.disabled ? 1 : -1;
        } else {
          const aStartsWithLetter = /^[a-zA-Z]/.test(a.label.toString());
          const bStartsWithLetter = /^[a-zA-Z]/.test(b.label.toString());
          if (aStartsWithLetter && bStartsWithLetter) {
            return a.label.toString().localeCompare(b.label.toString());
          } else if (!aStartsWithLetter && !bStartsWithLetter) {
            return parseInt(a.label.toString(), 10) - parseInt(b.label.toString(), 10);
          } else if (!aStartsWithLetter) {
            return -1;
          } else {
            return 1;
          }
        }
      });

    setParkingLots(options ?? []);
  }, [data]);

  return (
    <InputSelect
      id="Parking-Lot-Select"
      name="parkingLot"
      options={parkingLots}
      loading={isLoading}
      onInputChange={onSearchInputChange}
      inputValue={searchText}
      {...rest}
    />
  );
};

export { SelectParkingLot };
