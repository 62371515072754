import React, { useEffect, useState } from 'react';
import { InputSelect, InputSelectProps, OptionType } from '@/components/InputSelect';
import { useHousingCompanies } from '@/utils/hooks/useHousingCompanies';
import { useDebounce } from '@/utils/hooks/useDebounce';

interface Props
  extends Omit<InputSelectProps, 'inputValue' | 'onInputChange' | 'id' | 'name' | 'options' | 'loading'> {}

const SelectHousingCompany: React.FC<Props> = props => {
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 300);
  const { isLoading, data } = useHousingCompanies(debouncedSearchText, { useErrorBoundary: true });
  const [housingCompanies, setHousingCompany] = useState<OptionType[]>([]);

  const onSearchInputChange = (search: string) => {
    setSearchText(search);
  };

  useEffect(() => {
    const nullableOptions = data?.map(housingCompany => {
      if (!housingCompany.housingCompanyName || !housingCompany.id) {
        return null;
      }

      // TODO: use housingCompany.city in the future when SF data is fixed
      const addressParts = housingCompany.housingCompanyAddress?.split(' ');
      let city;
      if (addressParts && addressParts.length > 0) {
        city = addressParts[addressParts.length - 1];
      }
      const option: OptionType = {
        label: city ? `${housingCompany.housingCompanyName}, ${city}` : housingCompany.housingCompanyName,
        value: housingCompany.id
      };
      return option;
    });
    const options = nullableOptions
      ?.filter((option): option is OptionType => option !== null)
      .sort((a, b) => a.label.toString().localeCompare(b.label.toString()));

    setHousingCompany(options ?? []);
  }, [data]);

  return (
    <InputSelect
      id="Housing-Company-Select"
      name="housingCompany"
      options={housingCompanies}
      loading={searchText != debouncedSearchText || isLoading}
      onInputChange={onSearchInputChange}
      inputValue={searchText}
      {...props}
    />
  );
};

export { SelectHousingCompany };
