import React from 'react';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { cn } from '@/utils/utils';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Props as RadioItemProps } from './RadioButton';

interface Props {
  children: React.ReactNode;
  name: string;
  className?: string;
}

const RadioGroup: React.FC<Props> = ({ children, name, className }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, name } }) => (
        <RadixRadioGroup.Root
          value={value ? value.value : null}
          onValueChange={onChangeValue => {
            let selectedChild;
            if (Array.isArray(children)) {
              // Child can be null when we accept nodes that arent react components (such as divs)
              selectedChild = children.find(child => child && child.props && child.props.value == onChangeValue);
            } else {
              selectedChild = children;
            }

            const label = selectedChild?.props.label;
            if (label) {
              onChange({
                label: label,
                value: onChangeValue
              });
            }
          }}
          name={name}
          className={cn(className, 'gap-4', 'flex', 'flex-wrap')}
        >
          {React.Children.map(children, (child, index) => {
            if (!React.isValidElement<RadioItemProps>(child) || typeof child.type == 'string') {
              return child;
            }
            return React.cloneElement<RadioItemProps>(child, {
              isSelected: value != undefined && child.props.value == value?.value
            });
          })}
        </RadixRadioGroup.Root>
      )}
    />
  );
};

export { RadioGroup };
