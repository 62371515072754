import React from 'react';
import { useUserLocation } from '@/utils/hooks/useUserLocation';
import { useHousingCompaniesByLocation } from '@/utils/hooks/useHousingCompaniesByLocation';
import { RadioGroup } from '@/components/radio/RadioGroup';
import { RadioButton, RadioSize } from '@/components/radio/RadioButton';
import { cn } from '@/utils/utils';
import locale from '@/public/locales/nb/checkout.json';
import { Typography } from '@/components/Typography';

interface Props {
  className?: string;
  name: string;
}

const RadioHousingCompanyUserLocation: React.FC<Props> = ({ name, className }) => {
  const { userLocation, isLoading: isLoadingLocation, geoLocationUnavailable } = useUserLocation();
  const { isLoading: isLoadingData, data } = useHousingCompaniesByLocation(userLocation);
  const isLoading = isLoadingLocation || isLoadingData;

  const geoLocationUnavailableState = !isLoading && geoLocationUnavailable;
  const notFoundState = !isLoading && !geoLocationUnavailable && (data == undefined || data.length == 0);

  if (isLoading || geoLocationUnavailableState || notFoundState) {
    return null;
  }

  return (
    <div className={className}>
      <Typography type="S-B" component="p" className={cn('mb-3')}>
        {locale.step.housingCompany.housingCompanySelectByLocation.title}
      </Typography>
      {isLoading && (
        <Typography type="XS-B" component="h1" className={cn('text-gray-100')}>
          {locale.step.housingCompany.housingCompanySelectByLocation.loading}
        </Typography>
      )}
      {geoLocationUnavailableState && (
        <Typography type="XS-B" component="h1" className={cn('text-system-red-100')}>
          {locale.step.housingCompany.housingCompanySelectByLocation.geoLocationUnavailable}
        </Typography>
      )}
      {notFoundState && (
        <Typography type="XS-B" component="h1" className={cn('text-gray-100')}>
          {locale.step.housingCompany.housingCompanySelectByLocation.notFound}
        </Typography>
      )}
      {!isLoading && !geoLocationUnavailable && data && (
        <RadioGroup name={name}>
          {data
            .filter(housingCompany => housingCompany.id != null && housingCompany.housingCompanyName != null)
            .map(housingCompany => (
              <RadioButton
                radioSize={RadioSize.Small}
                key={housingCompany.id}
                id={housingCompany.id as string}
                value={housingCompany.id as string}
                label={housingCompany.housingCompanyName as string}
              />
            ))}
        </RadioGroup>
      )}
    </div>
  );
};

export { RadioHousingCompanyUserLocation };
