import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CheckoutLayout, CheckoutProps } from '@/layouts/CheckoutLayout';
import { cn } from '@/utils/utils';
import { SelectHousingCompany } from '@/components/SelectHousingCompany';
import * as yup from 'yup';
import { OptionType, StringOptionType } from '@/components/InputSelect';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonSize, ButtonVariant } from '@/components/Button';
import { Alert, AlertType } from '@/components/Alert';
import { SelectChargingFacility } from '@/components/SelectChargingFacility';
import { SelectParkingLot } from '@/components/SelectParkingLot';
import locale from '@/public/locales/nb/checkout.json';
import { useAtom } from 'jotai';
import { locationAtom } from '@/state/checkout/location';
import { RadioHousingCompanyUserLocation } from '@/components/RadioHousingCompanyUserLocation';
import { AddLeadDialog } from '@/components/AddLeadDialog';
import {
  fetcher as chargingFacilityFetcher,
  getQueryKey as getChargingFacilityQueryKey,
  useChargingFacility
} from '@/utils/hooks/useChargingFacility';
import { useQueryClient } from '@tanstack/react-query';
import { Typography } from '@/components/Typography';
import { useRouter } from 'next/router';
import { useHousingCompany } from '@/utils/hooks/useHousingCompany';

interface schema {
  housingCompany?: StringOptionType;
  housingCompanyByLocation?: StringOptionType;
  chargingFacility: StringOptionType;
  parkingLot: StringOptionType;
}

const HousingCompany = ({ next }: CheckoutProps) => {
  const router = useRouter();
  const housingCompanyIdFromQuery = router.query.housingCompanyId;
  const queryClient = useQueryClient();
  const [location, setLocation] = useAtom(locationAtom);
  const prevLocation = useRef(location);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape(
    {
      housingCompany: yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.string().required()
        })
        .when('housingCompanyByLocation', {
          is: (housingCompanyByLocation: OptionType) => housingCompanyByLocation == undefined,
          then: schema => schema.required(),
          otherwise: schema => schema.nullable()
        })
        .default(null),
      housingCompanyByLocation: yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.string().required()
        })
        .when('housingCompany', {
          is: (housingCompany?: OptionType) => housingCompany == undefined,
          then: schema => schema.required(),
          otherwise: schema => schema.nullable()
        })
        .default(null),
      chargingFacility: yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.string().required()
        })
        .required()
        .default(null),
      parkingLot: yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.string().required()
        })
        .test({
          name: 'test-parking-lot',
          test: async (value, context) => {
            let result = value != undefined;
            if (context.parent.chargingFacility) {
              const chargingFacilityId = context.parent.chargingFacility.value;
              const queryKey = getChargingFacilityQueryKey(chargingFacilityId);
              const chargingFacility = await queryClient.fetchQuery({
                queryKey,
                queryFn: () => chargingFacilityFetcher(chargingFacilityId)
              });
              result = chargingFacility.fixedParking ? result : true;
            }
            return result;
          }
        })
        .nullable()
        .default(null)
    },
    [['housingCompany', 'housingCompanyByLocation']]
  );

  const { watch, resetField, setValue, ...formMethods } = useForm<schema>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(() => {
      return {
        housingCompany: location?.housingCompany,
        chargingFacility: location?.chargingFacility,
        parkingLot: location?.parkingLot
      };
    }, [location])
  });

  const [leadDialogOpen, setLeadDialogOpen] = useState(false);
  const toggleLeadDialogOpen = () => setLeadDialogOpen(open => !open);

  const { data: housingCompanyData } = useHousingCompany(
    typeof housingCompanyIdFromQuery == 'string' ? housingCompanyIdFromQuery : '',
    {
      enabled:
        housingCompanyIdFromQuery != undefined &&
        housingCompanyIdFromQuery != '' &&
        typeof housingCompanyIdFromQuery == 'string'
    }
  );

  useEffect(() => {
    if (housingCompanyData && housingCompanyData.housingCompanyName && housingCompanyData.id) {
      const addressParts = housingCompanyData.housingCompanyAddress?.split(' ');
      let city;
      if (addressParts && addressParts.length > 0) {
        city = addressParts[addressParts.length - 1];
      }
      const option: StringOptionType = {
        label: city ? `${housingCompanyData.housingCompanyName}, ${city}` : housingCompanyData.housingCompanyName,
        value: housingCompanyData.id
      };
      setValue('housingCompany', option);
    }
  }, [housingCompanyData]);

  const facilityId = useWatch({ name: 'chargingFacility', control: formMethods.control });
  const { isLoading: isLoadingChargingFacility, data: chargingFacility } = useChargingFacility(
    facilityId && facilityId.value,
    {
      enabled: facilityId != undefined,
      useErrorBoundary: true
    }
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'housingCompany' && value.housingCompany != undefined && type === 'change') {
        resetField('housingCompanyByLocation', {
          defaultValue: null
        });
      }
      if (name === 'housingCompanyByLocation' && value.housingCompanyByLocation != undefined && type === 'change') {
        resetField('housingCompany', {
          defaultValue: null
        });
      }
      if ((name === 'housingCompany' || name === 'housingCompanyByLocation') && type === 'change') {
        resetField('chargingFacility', {
          defaultValue: null
        });
        resetField('parkingLot', {
          defaultValue: null
        });
      }
      if (name == 'chargingFacility' && type === 'change') {
        resetField('parkingLot', {
          defaultValue: null
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, resetField]);

  useEffect(() => {
    if (formMethods.formState.errors.housingCompany == undefined) {
      formMethods.clearErrors();
    }
  }, [formMethods.formState.errors.housingCompany]); // eslint-disable-line react-hooks/exhaustive-deps

  const goNext = () => {
    next().finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (prevLocation.current != location) {
      goNext();
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = formMethods.handleSubmit(async data => {
    let housingCompany;
    if (data.housingCompanyByLocation) {
      housingCompany = data.housingCompanyByLocation;
    } else {
      housingCompany = data.housingCompany;
    }

    setIsLoading(true);
    if (
      housingCompany != location?.housingCompany ||
      data.chargingFacility != location?.chargingFacility ||
      data.parkingLot != location?.parkingLot
    ) {
      setLocation({
        housingCompany: housingCompany as StringOptionType,
        chargingFacility: data.chargingFacility,
        parkingLot: data.parkingLot
      });
    } else {
      goNext();
    }
  });

  return (
    <div className={cn('flex', 'flex-col', 'w-full')}>
      <FormProvider watch={watch} resetField={resetField} setValue={setValue} {...formMethods}>
        <form onSubmit={onSubmit} className={cn('flex', 'flex-col', 'items-baseline', 'mb-20', 'md:mb-16')}>
          <Typography type="L-H" component="h1" className={cn('flex', 'md:flex-col', 'mb-20', 'md:mb-10')}>
            <span>{locale.step.housingCompany.title.part1}&nbsp;</span>
            <span>{locale.step.housingCompany.title.part2}</span>
          </Typography>
          <div className={cn('w-full')}>
            <SelectHousingCompany
              fullWidth
              label={locale.step.housingCompany.housingCompanySelect.label}
              placeholder={locale.step.housingCompany.housingCompanySelect.placeholder}
              helperText={locale.step.housingCompany.housingCompanySelect.helperText}
              className={cn('mb-8', 'md:mb-6', 'last:mb-16', 'last:md:mb-10')}
            />
            {process.env.NEXT_PUBLIC_FEATURE_FLAG_LOCATION_BASED_SEARCH_ENABLED === 'true' && (
              <RadioHousingCompanyUserLocation
                name="housingCompanyByLocation"
                className={cn('mb-8', 'md:mb-6', 'last:mb-16', 'last:md:mb-10')}
              />
            )}
            {formMethods.formState.errors.housingCompany && (
              <Alert
                type={AlertType.Alert}
                body={locale.step.housingCompany.housingCompanySelect.error.body}
                className={cn('last:mb-16', 'last:md:mb-10')}
              />
            )}
            {(watch('housingCompany') != undefined || watch('housingCompanyByLocation') != undefined) && (
              <>
                <SelectChargingFacility
                  housingCompanyId={(
                    (watch('housingCompany') ?? watch('housingCompanyByLocation')) as OptionType
                  ).value.toString()}
                  fullWidth
                  label={locale.step.housingCompany.chargingFacilitySelect.label}
                  placeholder={locale.step.housingCompany.chargingFacilitySelect.placeholder}
                  className={cn('mb-3', 'last:mb-16', 'last:md:mb-10')}
                />
                {chargingFacility && chargingFacility.fixedParking && (
                  <SelectParkingLot
                    chargingFacilityId={watch('chargingFacility') && watch('chargingFacility').value.toString()}
                    fullWidth
                    placeholder={locale.step.housingCompany.parkingLotSelect.placeholder}
                    disabled={watch('chargingFacility') == undefined}
                    className={cn('last:mb-16', 'last:md:mb-10')}
                  />
                )}
                {formMethods.formState.errors.chargingFacility && (
                  <Alert
                    type={AlertType.Alert}
                    className={cn('mt-3', 'last:mb-16', 'last:md:mb-10')}
                    body={locale.step.housingCompany.chargingFacilitySelect.error.body}
                  />
                )}
                {formMethods.formState.errors.parkingLot && !formMethods.formState.errors.chargingFacility && (
                  <Alert
                    type={AlertType.Alert}
                    className={cn('mt-3', 'last:mb-16', 'last:md:mb-10')}
                    body={locale.step.housingCompany.parkingLotSelect.error.body}
                  />
                )}
              </>
            )}
          </div>
          <div className={cn('flex', 'flex-row', 'md:flex-col-reverse', 'w-full', 'justify-between', 'gap-4')}>
            <Button externalHref="/login" buttonVariant={ButtonVariant.Outline} type="button">
              {locale.step.housingCompany.existingCustomerButton}
            </Button>
            <Button
              buttonVariant={ButtonVariant.Contained}
              type="submit"
              className={cn('md:w-full', 'min-w-[160px]', 'md:min-w-[unset]', 'md:basis-2/4')}
              loading={isLoading}
              disabled={isLoading}
            >
              {locale.step.housingCompany.nextButton}
            </Button>
          </div>
        </form>
      </FormProvider>
      <Alert
        header={locale.step.housingCompany.TipUsAlert.header}
        body={locale.step.housingCompany.TipUsAlert.body}
        buttonText={locale.step.housingCompany.TipUsAlert.button}
        buttonSize={ButtonSize.Medium}
        buttonOnClick={toggleLeadDialogOpen}
      />
      <AddLeadDialog open={leadDialogOpen} onClose={toggleLeadDialogOpen} />
    </div>
  );
};

HousingCompany.getLayout = (page: React.ReactNode) => {
  return <CheckoutLayout>{page}</CheckoutLayout>;
};

export default HousingCompany;
