import { QueryKey, QueryObserverResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ChargingFacilityDTO, ChargingFacilityService } from '@/utils/api/services/openapi';
import { LoggingContextType, useLogger } from '@/utils/logger';

const chargingFacilitiesDefaultQueryKey = 'getChargingFacilities';

const fetcher = async (housingCompanyId: string, logger?: LoggingContextType) => {
  if (housingCompanyId == undefined || housingCompanyId == '') {
    return [];
  }
  const chargingFacilities = await ChargingFacilityService.getChargingFacilitiesByHousingCompanyId(housingCompanyId);
  if (chargingFacilities.length === 0) {
    logger?.warn('Got 0 charging facilities from housing company {HousingCompany}', housingCompanyId);
  }
  return chargingFacilities;
};

const useChargingFacilities = (
  housingCompanyId: string,
  options: Omit<UseQueryOptions<ChargingFacilityDTO[], unknown, ChargingFacilityDTO[], QueryKey>, 'initialData'> = {}
): QueryObserverResult<ChargingFacilityDTO[]> => {
  const logger = useLogger();

  const query = useQuery<ChargingFacilityDTO[]>({
    queryKey: [chargingFacilitiesDefaultQueryKey, housingCompanyId],
    queryFn: () => fetcher(housingCompanyId, logger),
    ...options
  });

  return { ...query, isLoading: query.isLoading && options.enabled !== false } as QueryObserverResult<
    ChargingFacilityDTO[]
  >;
};

export { useChargingFacilities };
