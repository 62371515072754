import { QueryKey, QueryObserverResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HousingCompanyService } from '@/utils/api/services/openapi/services/HousingCompanyService';
import { ExternalAccountResponse } from '@/utils/api/services/openapi';
import { UserLocation } from '@/utils/hooks/useUserLocation';

const housingCompaniesByLocationDefaultQueryKey = 'getHousingCompanies';

const fetcher = (location?: UserLocation) => {
  if (location == undefined) {
    return [];
  }

  return HousingCompanyService.searchHousingCompaniesByLocation({ latitude: location.lat, longitude: location.lon });
};

const useHousingCompaniesByLocation = (
  location?: UserLocation,
  options: Omit<
    UseQueryOptions<ExternalAccountResponse[], unknown, ExternalAccountResponse[], QueryKey>,
    'initialData'
  > = {}
): QueryObserverResult<ExternalAccountResponse[]> => {
  const query = useQuery<ExternalAccountResponse[]>({
    queryKey: [housingCompaniesByLocationDefaultQueryKey, location?.lat, location?.lon],
    queryFn: () => fetcher(location),
    ...options
  });

  return { ...query, isLoading: query.isLoading && options.enabled !== false } as QueryObserverResult<
    ExternalAccountResponse[]
  >;
};

export { useHousingCompaniesByLocation };
